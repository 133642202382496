<template>
  <div>
    <div v-if="!showLoader">
      <m-page-title title="Amazon" />
      <div class="col-12">
        <b-row class="align-items-center my-4">
          <b-colxx md="5" sm="12"
            ><b-form id="customer-search-main" @submit.prevent="getAmazonOrders">
              <b-form-group
                id="search-fieldset"
                label="Order Number"
                label-for="search-order-number"
                class="field-label-form-group"
              >
                <b-input-group class="">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                  <b-form-input
                    id="search-order-number-input"
                    v-model="searchKeyword"
                    placeholder="Search by order number..."
                    type="text"
                  >
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-form>
          </b-colxx>
          <b-colxx md="2" sm="12">
            <b-form-group
              id="search-from-fieldset"
              label="From"
              label-for="search-from-date"
              class="field-label-form-group"
            >
              <b-form-input id="search-from-date" v-model="searchFromDate" type="date" />
            </b-form-group>
          </b-colxx>
          <b-colxx md="2" sm="12">
            <b-form-group
              id="search-to-fieldset"
              label="To"
              label-for="search-to-date"
              class="field-label-form-group"
            >
              <b-form-input id="search-to-date" v-model="searchToDate" type="date" />
            </b-form-group>
          </b-colxx>
          <b-colxx md="3" sm="12">
            <b-button variant="success" @click="getAmazonOrders()">Search</b-button>
            <b-button variant="info" @click="clearSearchFilters()">Clear</b-button>
            <b-button variant="secondary" @click="exportToExcel()">Generate Report</b-button>
          </b-colxx>
        </b-row>
        <b-row>
          <b-colxx md="3" sm="12">
            <base-checkbox v-model="isNotReconciled" :checked="isNotReconciled" @update-checkbox="getNotReconciledOrders()">Not Reconciled</base-checkbox>
            <base-checkbox v-model="isNoSageSalesOrderId" :checked="isNoSageSalesOrderId" @update-checkbox="getNoSageSalesOrderIdOrders()">No Sage Sales Order ID</base-checkbox>
          </b-colxx>
        </b-row>
        <div class="card-body">
          <b-table
            id="amazon-order-table"
            ref="__amazonOrderTable"
            responsive
            head-variant="dark"
            :items="amazonOrders"
            :fields="amazon_order_list_fields"
            per-page="0"
          >
            <template #cell(is_reconciled_yn)="row">
              <base-checkbox v-if="row.item.sage_sales_order_id !== null" :checked="row.item.is_reconciled_yn" @update-checkbox="updateReconciliationCheckbox(row.item.id)" />
            </template>
            <template #cell(action)="row">
              <i
                class="fas fa-eye text-default icon-pointer"
                @click="getOrderDetails(row.item.id)"
              />
              <i v-if="orderIsFlagged(row.item)" class="fas fa-flag text-warning ml-2" />
              <i v-if="row.item.has_promotion_discount_applied_yn" class="fas fa-tag text-info ml-2" />
            </template>
            <template #cell(OrderTotal)="row">
              <span
                >{{ row.item.order_total_amount }}
                {{ row.item.order_total_currency_code }}</span
              >
            </template>
          </b-table>
        </div>
        <b-pagination
          v-model="current_page"
          :total-rows="total_rows"
          :per-page="per_page"
          :number-of-pages="totalPages"
          size="sm"
          class="my-0"
          aria-controls="amazon-order-table"
          @input="getAmazonOrders(current_page)"
        ></b-pagination>
      </div>
    </div>

    <b-modal
      id="order-details-modal"
      v-model="showOrderDetailsModal"
      title="Order Details"
      centered
      scrollable
      header-bg-variant="info"
      header-text-variant="white"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      size="xl"
    >
      <m-spinny-message v-if="loadingOrderDetails" />
      <div v-else class="order-details">
        <b-alert
          v-model="showAlert"
          :variant="alertVariant"
          class="w-100"
          dismissible
          >{{ alertMessage }}</b-alert
        >
        <!-- Order Header Info -->
        <b-card class="mb-3">
          <b-row>
            <b-col md="6">
              <h6 class="text-muted mb-2">Order Information</h6>
              <p class="mb-1">
                <strong>Order ID:</strong> {{ orderDetails.amazon_order_id }}
              </p>
              <p class="mb-1"><strong>Status:</strong> {{ orderDetails.order_status }}</p>
              <p class="mb-1">
                <strong>Purchase Date:</strong>
                {{ new Date(orderDetails.purchase_date).toLocaleString() }}
              </p>
              <p class="mb-1">
                <strong>Last Updated:</strong>
                {{ new Date(orderDetails.last_update_date).toLocaleString() }}
              </p>
              <p class="mb-1">
                <strong>Fulfillment:</strong> {{ orderDetails.fulfillment_channel }}
              </p>
              <p class="mb-1">
                <strong>Payment Method:</strong> {{ orderDetails.payment_method }}
              </p>
              <p class="mb-1">
                <strong>Reconciled:</strong> {{ orderDetails.is_reconciled_yn ? 'Yes' : 'No' }}
                <span v-if="orderDetails.is_reconciled_yn">
                  <strong>Reconciled Date:</strong> {{ new Date(orderDetails.reconciled_date).toLocaleString() }}
                </span>
              </p>
            </b-col>
            <b-col md="6">
              <h6 class="text-muted mb-2">Shipping Information</h6>
              <p class="mb-1">
                <strong>Service Level:</strong> {{ orderDetails.ship_service_level }}
              </p>
              <p class="mb-1">
                <strong>City:</strong> {{ orderDetails.shipping_address_city }}
              </p>
              <p class="mb-1">
                <strong>Province:</strong>
                {{ orderDetails.shipping_address_state_or_region }}
              </p>
              <p class="mb-1">
                <strong>Postal Code:</strong>
                {{ orderDetails.shipping_address_postal_code }}
              </p>
              <p class="mb-1">
                <strong>Country:</strong> {{ orderDetails.shipping_address_country_code }}
              </p>
              <p class="mb-1">
                <m-text-input
                  id="sage-sales-order-id"
                  :required="false"
                  :group="false"
                  label="Sage Order ID"
                  :value="orderDetails.sage_sales_order_id"
                  type="text"
                  @update-value="
                    (input) => {
                      orderDetails.sage_sales_order_id = input;
                    }
                  "
                />
              </p>
            </b-col>
          </b-row>
        </b-card>

        <!-- Order Items -->
        <b-card>
          <h6 class="text-muted mb-3">Order Items</h6>
          <div
            v-for="item in orderDetails.order_items"
            :key="item.id"
            class="order-item mb-3 pb-3 border-bottom"
          >
            <b-row>
              <b-col md="8">
                <p class="mb-1">
                  <strong>{{ item.title }}</strong>
                </p>
                <p class="mb-1 text-muted">
                  SKU: {{ item.seller_sku }} | ASIN: {{ item.asin }}
                </p>
                <p class="mb-1">Order Item ID: {{ item.order_item_id }}</p>
              </b-col>
              <b-col md="4">
                <div class="text-right">
                  <p class="mb-1">
                    <strong>Price:</strong> {{ item.item_price_amount ? item.item_price_amount.toFixed(2) : 'N/A' }}
                    {{ item.item_price_currency_code ? item.item_price_currency_code : 'N/A' }}
                  </p>
                  <m-text-input
                    id="item-gst-amount"
                    :required="false"
                    :group="false"
                    label="GST Amount"
                    :value="item.gst_amount"
                    type="decimal"
                    @update-value="
                      (input) => {
                        item.gst_amount = input;
                      }
                    "
                  />
                  <m-text-input
                    id="item-hst-amount"
                    :required="false"
                    :group="false"
                    label="HST Amount"
                    :value="item.hst_amount"
                    type="decimal"
                    @update-value="
                      (input) => {
                        item.hst_amount = input;
                      }
                    "
                  />
                  <m-text-input
                    id="item-pst-amount"
                    :required="false"
                    :group="false"
                    label="PST Amount"
                    :value="item.pst_amount"
                    type="decimal"
                    @update-value="
                      (input) => {
                        item.pst_amount = input;
                      }
                    "
                  />

                  <p class="mb-1">
                    <strong>Tax:</strong> {{ item.item_tax_amount ? item.item_tax_amount.toFixed(2) : 'N/A' }}
                    {{ item.item_tax_currency_code ? item.item_tax_currency_code : 'N/A' }}
                  </p>
                  <p class="mb-1">
                    <strong>Quantity:</strong> {{ item.quantity_ordered }} (Shipped:
                    {{ item.quantity_shipped }})
                  </p>
                  <p v-if="item.promotion_discount_amount > 0" class="mb-1">
                    <strong>Discount:</strong> {{ item.promotion_discount_amount ? item.promotion_discount_amount.toFixed(2) : 'N/A' }}
                    {{ item.promotion_discount_currency_code ? item.promotion_discount_currency_code : 'N/A' }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>

          <!-- Promotions -->
          <div v-if="hasPromotions" class="mt-4 mb-3">
            <h6 class="text-muted mb-3">Promotions</h6>
            <div v-for="item in orderDetails.order_items" :key="'promo-' + item.id">
              <div class="promotion-item mb-2">
                <b-row>
                  <b-col md="8">
                    <p class="mb-1">
                      <strong>{{ item.title }}</strong>
                    </p>
                    <p v-if="item.promotion_ids" class="mb-1 text-muted">
                      Promotion IDs: {{ item.promotion_ids.join(", ") }}
                    </p>
                  </b-col>
                  <b-col md="4">
                    <div class="text-right">
                      <p class="mb-1">
                        <strong>Discount:</strong> {{ item.promotion_discount_amount ? item.promotion_discount_amount.toFixed(2) : 'N/A' }}
                        {{ item.promotion_discount_currency_code ? item.promotion_discount_currency_code : 'N/A' }}
                      </p>
                      <p class="mb-1">
                        <strong>Tax Adjustment:</strong>
                        {{ item.promotion_discount_tax_amount ? item.promotion_discount_tax_amount.toFixed(2) : 'N/A' }}
                        {{ item.promotion_discount_tax_currency_code ? item.promotion_discount_tax_currency_code : 'N/A' }}
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>

          <!-- Order Total -->
          <b-row class="mt-3">
            <b-col>
              <h5 class="text-right totals">
                Total: {{ orderDetails.order_total_amount ? orderDetails.order_total_amount.toFixed(2) : 'N/A' }}
                {{ orderDetails.order_total_currency_code }}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <h5 class="text-left">
                Shipping Price: {{ orderDetails.shipping_price_amount ? orderDetails.shipping_price_amount : '0.00' }}
              </h5>
            </b-col>  
            <b-col md="6">
              <h5 class="text-right">
                GST: {{ orderDetails.gst_total ? orderDetails.gst_total.toFixed(2) : '0.00' }}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <h5 class="text-left">
                Shipping Tax: {{ orderDetails.shipping_tax_amount ? orderDetails.shipping_tax_amount : '0.00' }}
              </h5>
            </b-col>
            <b-col md="6">
              <h5 class="text-right">
                PST: {{ orderDetails.pst_total ? orderDetails.pst_total.toFixed(2) : '0.00' }}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <h5 class="text-left">
                Shipping Discount: {{ orderDetails.shipping_discount_amount ? orderDetails.shipping_discount_amount : '0.00' }}
              </h5>
            </b-col>
            <b-col md="6">
              <h5 class="text-right">
                HST: {{ orderDetails.hst_total ? orderDetails.hst_total.toFixed(2) : '0.00' }}
              </h5>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <template #modal-footer="{}">
        <b-button variant="primary" @click="showOrderDetailsModal = false"
          >Close</b-button
        >
        <b-button variant="success" :disabled="loadingOrderDetails" @click="saveOrderDetails">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import django_session from "@/api/django_session";
import { mapGetters } from "vuex";
import MSpinnyMessage from "@/components/loader/MSpinnyMessage.vue";
import BaseCheckbox from "@/components/input/BaseCheckbox.vue";
import { utils, writeFile } from 'xlsx';

const flaggedProvinces = ['British Columbia', 'BC', 'bc', 'Bc', 'Saskatchewan', 'Sask', 'SK', 'Manitoba', 'MANITOBA', 'MB', 'mb', 'Mb', 'Quebec', 'Québec', 'QC', 'qc', 'Qc'];

export default {
  name: "AmazonHome",

  components: {
    BaseCheckbox,
    MSpinnyMessage,
  },

  data() {
    return {
      gettingData: false,
      amazonOrders: [],
      amazon_order_list_fields: [
        { key: "is_reconciled_yn", label: "" },
        { key: "action", label: "Action" },
        { key: "sage_sales_order_id", label: "Sage Order ID" },
        { key: "amazon_order_id", label: "Order ID" },
        { key: "purchase_date", label: "Purchase Date" },
        { key: "order_status", label: "Order Status" },
        { key: "order_total_amount", label: "Order Total" },
        { key: "gst_total", label: "GST" },
        { key: "pst_total", label: "PST" },
        { key: "hst_total", label: "HST" },
        { key: "shipping_price_amount", label: "Shipping" },
        { key: "shipping_tax_amount", label: "Shipping Tax" },
        { key: "shipping_discount_amount", label: "Shipping Discount" },
        { key: "shipping_address_city", label: "City" },
        { key: "shipping_address_state_or_region", label: "Prov" },
      ],
      searchKeyword: "",
      searchFromDate: null,
      searchToDate: null,
      isNotReconciled: false,
      isNoSageSalesOrderId: false,
      per_page: 10,
      total_rows: 0,
      current_page: 1,
      previousPageLink: null,
      nextPageLink: null,
      showOrderDetailsModal: false,
      orderDetails: {},
      loadingOrderDetails: false,
      showAlert: false,
      alertVariant: "",
      alertMessage: "",
    };
  },

  mounted() {
    document.title = "Amazon Orders";
    // this.getAmazonOrders();
  },

  computed: {
    isPreviousPageDisabled() {
      return this.current_page === 1;
    },
    isNextPageDisabled() {
      return this.current_page === this.total_rows;
    },
    totalPages() {
      return Math.ceil(this.total_rows / this.per_page);
    },

    hasPromotions() {
      if (!this.orderDetails.order_items) return false;
      return this.orderDetails.order_items.some(
        (item) =>
          item.promotion_discount_amount > 0 ||
          (item.promotion_ids && item.promotion_ids.length > 0)
      );
    },
    orderIsFlagged() {
      return(item => flaggedProvinces.includes(item.shipping_address_state_or_region));
    },
    ...mapGetters("common", ["showLoader"]),
  },

  methods: {
    async getAmazonOrders() {
      this.$store.dispatch("common/showLoader", true);
      await django_session
        .get("erp/amazon/get-orders/", {
          params: {
            page: this.current_page,
            searchKeyword: this.searchKeyword,
            searchFromDate: this.searchFromDate,
            searchToDate: this.searchToDate,
            isNotReconciled: this.isNotReconciled,
            isNoSageSalesOrderId: this.isNoSageSalesOrderId,
          },
        })
        .then((response) => {
          if (response.data.results) {
            response.data.results.forEach((order) => {
              order.purchase_date = new Date(order.purchase_date).toUTCString();
            });
            this.amazonOrders = response.data.results;
            this.total_rows = response.data.count;
            this.current_page = response.data.page;
            this.previousPageLink = response.data.links.previous;
            this.nextPageLink = response.data.links.next;
          } else {
            response.data.forEach((order) => {
              order.purchase_date = new Date(order.purchase_date).toUTCString();
            });
            this.amazonOrders = response.data;
            this.total_rows = 0;
            this.current_page = 1;
            this.previousPageLink = null;
            this.nextPageLink = null;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.current_page = 1;
            this.total_rows = 0;
            this.amazonOrders = [];
            this.getAmazonOrders();
          }
        })
        .finally(() => {
          this.$store.dispatch("common/showLoader", false);
        });
    },

    getNotReconciledOrders() {
      this.isNotReconciled = true;
      this.isNoSageSalesOrderId = false;
      // this.searchKeyword = "";
      // this.searchFromDate = null;
      // this.searchToDate = null;
      // this.getAmazonOrders();
    },

    getNoSageSalesOrderIdOrders() {
      this.isNoSageSalesOrderId = true;
      this.isNotReconciled = false;
      // this.searchKeyword = "";
      // this.searchFromDate = null;
      // this.searchToDate = null;
      // this.getAmazonOrders();
    },

    clearSearchFilters() {
      this.searchKeyword = "";
      this.searchFromDate = null;
      this.searchToDate = null;
      this.amazonOrders = [];
      this.total_rows = 0;
      this.current_page = 1;
      this.previousPageLink = null;
      this.nextPageLink = null;
      this.isReconciled = null;
      this.noSageSalesOrderId = null;
    },

    exportToExcel() {
      console.log("Exporting to Excel");
      this.$store.dispatch("common/showLoader", true);
      django_session.get("erp/amazon/get-orders/", {
        params: {
          searchKeyword: this.searchKeyword,
          searchFromDate: this.searchFromDate,
          searchToDate: this.searchToDate,
          isReconciled: this.isReconciled,
          noSageSalesOrderId: this.noSageSalesOrderId,
          page_size: 0,
        },
      }).then((response) => {
        let fileName = "Amazon Orders.xlsx";
        
        // Transform the data into rows, with order items expanded
        const rows = [];
        response.data.forEach(order => {
          // For each order item, create a row with both order and item details
          order.order_items.forEach(item => {
            rows.push([
              order.amazon_order_id,
              order.sage_sales_order_id,
              order.purchase_date ? new Date(order.purchase_date).toUTCString() : "",
              order.reconciled_date ? new Date(order.reconciled_date).toUTCString() : "",
              order.order_total_amount.toFixed(2),
              order.gst_total ? order.gst_total.toFixed(2) : "0.00",
              order.pst_total ? order.pst_total.toFixed(2) : "0.00",
              order.hst_total ? order.hst_total.toFixed(2) : "0.00",
              order.shipping_price_amount || "0.00",
              order.shipping_tax_amount || "0.00",
              order.shipping_discount_amount || "0.00",
              order.shipping_address_city,
              order.shipping_address_state_or_region,
              // Item specific details
              item.seller_sku,
              item.asin,
              item.title,
              item.quantity_ordered,
              item.quantity_shipped,
              item.item_price_amount ? item.item_price_amount.toFixed(2) : "0.00",
              item.item_tax_amount ? item.item_tax_amount.toFixed(2) : "0.00",
              item.gst_amount ? item.gst_amount.toFixed(2) : "0.00",
              item.pst_amount ? item.pst_amount.toFixed(2) : "0.00",
              item.hst_amount ? item.hst_amount.toFixed(2) : "0.00",
              item.promotion_discount_amount ? item.promotion_discount_amount.toFixed(2) : "0.00"
            ]);
          });
        });

        // Create worksheet with headers
        const ws = utils.aoa_to_sheet([
          [
            // Order level headers
            "Amazon Order #", 
            "Sage Order #", 
            "Purchase Date", 
            "Reconciled Date", 
            "Order Total", 
            "Order GST", 
            "Order PST", 
            "Order HST", 
            "Order Shipping", 
            "Order Shipping Tax", 
            "Order Shipping Discount", 
            "City", 
            "Prov",
            // Item level headers
            "SKU",
            "ASIN",
            "Title",
            "Qty Ordered",
            "Qty Shipped",
            "Item Price",
            "Item Tax",
            "Item GST",
            "Item PST",
            "Item HST",
            "Item Promotion Discount"
          ],
          ...rows
        ]);

        // Set column widths
        ws['!cols'] = [
          { wch: 20 }, // Amazon Order #
          { wch: 15 }, // Sage Order #
          { wch: 30 }, // Purchase Date
          { wch: 30 }, // Reconciled Date
          { wch: 12 }, // Order Total
          { wch: 10 }, // Order GST
          { wch: 10 }, // Order PST
          { wch: 10 }, // Order HST
          { wch: 12 }, // Order Shipping
          { wch: 12 }, // Order Shipping Tax
          { wch: 15 }, // Order Shipping Discount
          { wch: 20 }, // City
          { wch: 10 }, // Prov
          { wch: 15 }, // SKU
          { wch: 15 }, // ASIN
          { wch: 50 }, // Title
          { wch: 12 }, // Qty Ordered
          { wch: 12 }, // Qty Shipped
          { wch: 12 }, // Item Price
          { wch: 12 }, // Item Tax
          { wch: 12 }, // Item GST
          { wch: 12 }, // Item PST
          { wch: 12 }, // Item HST
          { wch: 15 }  // Item Promotion Discount
        ];

        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Amazon Orders");
        writeFile(wb, fileName);
      }).catch((error) => {
        console.error("Export failed:", error);
      }).finally(() => {
        this.$store.dispatch("common/showLoader", false);
      });
    },

    async getOrderDetails(orderId) {
      this.showOrderDetailsModal = true;
      this.loadingOrderDetails = true;
      await django_session.get(`erp/amazon/get-order/${orderId}/`).then((response) => {
        console.log(response.data);
        this.orderDetails = response.data;
        this.loadingOrderDetails = false;
      }).catch((error) => {
        this.loadingOrderDetails = false;
      });
    },

    updateReconciliationCheckbox(orderId) {
      console.log(orderId);
      let order = this.amazonOrders.find(order => order.id === orderId);
      order.is_reconciled_yn = !order.is_reconciled_yn;
      console.log(order);
      this.orderDetails = order;
      this.saveOrderDetails();
    },

    async saveOrderDetails() {
      this.loadingOrderDetails = true;
      await django_session.put(`erp/amazon/get-order/${this.orderDetails.id}/`, this.orderDetails).then((response) => {
        this.orderDetails = response.data;
        let orderIndex = this.amazonOrders.findIndex(order => order.id === this.orderDetails.id);
        console.log(orderIndex);
        this.amazonOrders[orderIndex] = this.orderDetails;
        this.loadingOrderDetails = false;
        this.showAlert = true;
        this.alertVariant = "success";
        this.alertMessage = "Order details updated successfully";
      }).catch((error) => {
        this.loadingOrderDetails = false;
        this.showAlert = true;
        this.alertVariant = "danger";
        this.alertMessage = "Error updating order details";
      });
    },
  },
};
</script>

<style scoped>
.order-details .card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-item:last-child {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.promotion-item {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 4px;
  border-left: 4px solid #6c757d;
}

.totals {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
